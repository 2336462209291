body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root,
#root > div {
	height: 100%;
}

.ant-form-item-label label:not(.ant-form-item-required) {
	margin-left: 12px;
}

.ant-form-item-label,
.ant-form-item-required {
	white-space: pre-wrap !important;
	line-height: 1.2;
	overflow: visible !important;
}

.ant-form-item-control {
	align-self: center;
}

.tab-list .ant-tabs-nav {
	background: #f5f5f5;
}

.tab-list .ant-tabs-content-holder {
	padding: 20px;
}

.modal-style .ant-modal-header {
	padding: 20px 24px !important;
	border-bottom: 1px solid #f0f0f0 !important;
	border-radius: 2px 2px 0 0 !important;
}

.modal-style .ant-modal-content {
	padding: 0;
}

.modal-style .ant-modal-body {
	padding: 20px 24px !important;
}
.modal-style .ant-modal-footer {
	padding: 20px 24px !important;
	border-top: 1px solid #f0f0f0 !important;
}
