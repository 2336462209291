.sider {
	overflow: auto !important;
	height: 100vh !important;
	position: sticky !important;
	top: 0 !important;
	left: 0 !important;
}

.logoText {
	font-size: 21px !important;
	font-weight: 700 !important;
	line-height: 1.2 !important;
	letter-spacing: 6px !important;
	font-family: Exo !important;
	color: #fff !important;
	text-transform: uppercase !important;
}

.logoWithText {
	display: flex;
	justify-content: center;
	align-items: flex-end;
}
